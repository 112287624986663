import React, { useState, useEffect } from 'react';
import { Box, Button, Collapse, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { SmartInput, SmartSelect, SmartDatePicker } from './SmartInputSelect';
import warning_icon from '../icons/icons8-warning-100.png';
import { db } from '../components/firebase.js';
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';

const LicenseDetails = ({ formData, setFormData, handleNext, handlePrevious, text, language }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [licenseToDelete, setLicenseToDelete] = useState(null);
  
  // Filter out deleted licenses and sort to ensure default appears first
  const activeLicenses = formData.licenses
    .filter(license => !license.isDeleted)
    .sort((a, b) => {
      if (a.isDefault) return -1;
      if (b.isDefault) return 1;
      return 0;
    });
  
  const selectedLicense = activeLicenses[formData.selectedLicenseIndex] || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateSelectedLicense = (field, value) => {
    const updatedLicenses = [...formData.licenses];
    const activeIndex = updatedLicenses.findIndex(
      (license, idx) => !license.isDeleted && idx === formData.selectedLicenseIndex
    );
    if (activeIndex !== -1) {
      updatedLicenses[activeIndex] = {
        ...updatedLicenses[activeIndex],
        [field]: value
      };
      setFormData({ ...formData, licenses: updatedLicenses });
    }
  };

  const validate = () => {
    const newErrors = {};

    if (!selectedLicense.gunLicenseId?.trim()) {
      newErrors.gunLicenseId = 'יש למלא את מספר הרישיון';
    } else if (!selectedLicense.gunLicenseId.startsWith('12')) {
      newErrors.gunLicenseId = 'מספר הרישיון חייב להתחיל ב-12';
    }

    if (formData.registrationType !== 'הכשרה ראשונית') {
      if (!selectedLicense.licenseValidity) {
        newErrors.licenseValidity = 'יש למלא את תאריך תוקף הרישיון';
      } else {
        const selectedDate = new Date(selectedLicense.licenseValidity);
        const now = new Date();
        const fiveYearsLater = new Date();
        fiveYearsLater.setFullYear(now.getFullYear() + 5);

        if (selectedDate <= now) {
          newErrors.licenseValidity = 'תוקף הרישיון חייב להיות עתידי';
        } else if (selectedDate > fiveYearsLater) {
          newErrors.licenseValidity = 'תוקף הרישיון לא יכול להיות יותר מחמש שנים קדימה';
        }
      }

      if (!selectedLicense.imprintOnPistol?.trim()) {
        newErrors.imprintOnPistol = 'יש למלא את הטביעת האקדח';
      }

      if (!selectedLicense.pistolModel?.trim()) {
        newErrors.pistolModel = 'יש למלא את דגם האקדח';
      }

      if (!selectedLicense.pistolManufacturer || selectedLicense.pistolManufacturer === 'none') {
        newErrors.pistolManufacturer = 'יש לבחור יצרן';
      }

      if (!selectedLicense.ammunitionCaliber || selectedLicense.ammunitionCaliber === 'none') {
        newErrors.ammunitionCaliber = 'יש לבחור קליבר';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onNext = () => {
    setLoading(true);
    if (validate()) {
      handleNext();
    }
    setLoading(false);
  };

  const handleDeleteConfirmation = async () => {
    if (licenseToDelete !== null) {
      try {
        setLoading(true);
        const newLicenses = [...formData.licenses];
        const activeIndex = newLicenses.findIndex(
          (license, idx) => !license.isDeleted && idx === licenseToDelete
        );
        
        if (activeIndex !== -1) {
          // Update local state
          newLicenses[activeIndex] = {
            ...newLicenses[activeIndex],
            isDeleted: true,
            deletedAt: new Date().toISOString()
          };
          
          // Update Firestore
          const usersCollectionRef = collection(db, 'users');
          const q = query(usersCollectionRef, where("teudatZehut", "==", formData.teudatZehut));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userDocRef = userDoc.ref;
            await setDoc(userDocRef, { licenses: newLicenses }, { merge: true });
          }

          const newActiveIndex = Math.min(licenseToDelete, activeLicenses.length - 2);
          setFormData({
            ...formData,
            licenses: newLicenses,
            selectedLicenseIndex: newActiveIndex
          });
        }
      } catch (error) {
        console.error('Error updating license:', error);
      } finally {
        setLoading(false);
      }
    }
    setDeleteDialogOpen(false);
    setLicenseToDelete(null);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'right', mt: 2 }} dir="rtl">
      <div className="chrome-tab">
        <p>
          {formData.registrationType} - {formData.fullName ? formData.fullName + ' - ' : ''}{' '}
          {formData.teudatZehut} -{' '}
          {formData.registrationDate ? new Date(formData.registrationDate).toLocaleDateString('he-IL') : ''}
        </p>
      </div>

      {/* License selector if multiple exist */}
      {activeLicenses.length > 1 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
          <SmartSelect
            label="בחר רישיון"
            value={formData.selectedLicenseIndex.toString()}
            onChange={(e) =>
              setFormData({
                ...formData,
                selectedLicenseIndex: parseInt(e.target.value)
              })
            }
            options={[
              { value: '', label: 'בחר רישיון' },
              ...activeLicenses.map((license, index) => ({
                value: index.toString(),
                label: `${license.isDefault ? '⭐ ' : ''}רישיון ${index + 1} - ${license.pistolModel || 'ללא דגם'} - ${license.pistolManufacturer || 'ללא יצרן'}`
              }))
            ]}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, width: '100%' }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                const newLicenses = [...formData.licenses];
                const activeIndex = newLicenses.findIndex(
                  (license, idx) => !license.isDeleted && idx === formData.selectedLicenseIndex
                );
                if (activeIndex !== -1) {
                  newLicenses.forEach((license, idx) => {
                    license.isDefault = idx === activeIndex;
                  });
                  setFormData({
                    ...formData,
                    licenses: newLicenses
                  });
                }
              }}
              sx={{ 
                color: selectedLicense.isDefault ? 'gold' : 'inherit',
                borderColor: selectedLicense.isDefault ? 'gold' : 'inherit',
                fontSize: '0.8rem',
                width: '50%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {`הגדר ברירת מחדל - ${selectedLicense.pistolModel || 'ללא דגם'}`}
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                if (activeLicenses.length > 1) {
                  setLicenseToDelete(formData.selectedLicenseIndex);
                  setDeleteDialogOpen(true);
                }
              }}
              sx={{ 
                color: 'error.main', 
                borderColor: 'error.main',
                fontSize: '0.875rem',
                width: '50%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              disabled={activeLicenses.length <= 1}
            >
              {`הסר - ${selectedLicense.pistolModel || 'ללא דגם'}`}
            </Button>
          </Box>
        </Box>
      )}      

      {/* Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setLicenseToDelete(null);
        }}
        dir="rtl"
      >
        <DialogTitle>{"האם אתה בטוח שברצונך להסיר את הרישיון?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            פעולה זו תסיר את הרישיון מהרשימה הפעילה. הרישיון יישמר במערכת לצורך תיעוד היסטורי.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setDeleteDialogOpen(false);
              setLicenseToDelete(null);
            }}
            color="primary"
          >
            ביטול
          </Button>
          <Button 
            onClick={handleDeleteConfirmation}
            color="error"
            variant="contained"
          >
            הסר רישיון
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add new license */}
      <Box sx={{ mt: 1, mb: 2 }}>
        <Button
          variant="outlined"
          disabled={activeLicenses.length >= 5}
          onClick={() => {
            const newLicense = {
              gunLicenseId: '',
              imprintOnPistol: '',
              licenseValidity: '',
              pistolManufacturer: '',
              pistolModel: '',
              ammunitionCaliber: '',
              otherOption: '',
              isDeleted: false
            };
            setFormData((prev) => ({
              ...prev,
              licenses: [...prev.licenses, newLicense],
              selectedLicenseIndex: activeLicenses.length
            }));
          }}
        >
          הוסף רישיון נוסף
        </Button>
        {activeLicenses.length >= 5 && (
          <p style={{ color: '#666', margin: '4px 0 0', fontSize: '0.875rem' }}>
            ניתן להוסיף עד 5 רישיונות
          </p>
        )}
      </Box>

      {/* Warning */}
      {formData.registrationType !== 'הכשרה ראשונית' && (
        <Collapse in={true}>
          <Box className="holsterWarning" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <img className="warning_icon" src={warning_icon} alt="warning icon" style={{ margin: '0 8px' }} />
            <p style={{ margin: 0 }}>בשום פנים אין להוציא את האקדח מהנרתיק</p>
            <img className="warning_icon" src={warning_icon} alt="warning icon" style={{ margin: '0 8px' }} />
          </Box>
        </Collapse>
      )}

      {/* License Fields */}
      <SmartInput
        label={
          formData.registrationType === 'הכשרה ראשונית'
            ? `${text[language].gunLicenseId} - מופיע באישור המותנה`
            : text[language].gunLicenseId
        }
        value={selectedLicense.gunLicenseId || ''}
        onChange={(e) => updateSelectedLicense('gunLicenseId', e.target.value)}
        required
        error={!!errors.gunLicenseId}
        helperText={errors.gunLicenseId}
      />

      {formData.registrationType !== 'הכשרה ראשונית' && (
        <>
          <SmartInput
            label="מספר טבוע על הכלי - רשום ברישיון!"
            value={selectedLicense.imprintOnPistol || ''}
            onChange={(e) => updateSelectedLicense('imprintOnPistol', e.target.value)}
            required
            error={!!errors.imprintOnPistol}
            helperText={errors.imprintOnPistol}
          />

          <SmartSelect
            label="יצרן האקדח"
            value={selectedLicense.pistolManufacturer || ''}
            onChange={(e) => updateSelectedLicense('pistolManufacturer', e.target.value)}
            options={[
              { value: 'none', label: text[language].selectOption || 'בחר' },
              { value: 'Glock', label: 'Glock - גלוק' },
              { value: 'Sig Sauer', label: 'Sig Sauer - סיג סאוור' },
              { value: 'IWI', label: 'IWI' },
              { value: 'Smith & Wesson', label: "Smith & Wesson - סמית' אנד ווסון" },
              { value: 'CZ', label: 'CZ - סי זד' },
              { value: 'Beretta', label: 'Beretta - ברטה' },
              { value: 'Karin', label: 'Karin - קארין' },
              { value: 'Walther', label: 'Walther - וולטר' },
              { value: 'BUL', label: 'BUL - בול' },
              { value: 'FN', label: 'FN - בראונינג' },
              { value: 'Ruger', label: 'Ruger - רוגר' },
              { value: 'Springfield', label: 'Springfield - ספרינגפילד' },
              { value: 'Shadow System', label: 'Shadow Systems - שאדו סיסטמס' },
              { value: 'Colt', label: 'Colt - קולט' },
              { value: 'Mossberg', label: 'Mossberg - מוסברג' },
              { value: 'HELLCAT', label: 'HELLCAT - הלקט' },
              { value: 'Taurus', label: 'Taurus - טאורוס' },
              { value: 'Emtan', label: 'אמתן' },
              { value: 'Other', label: 'אחר' }
            ]}
            required
            error={!!errors.pistolManufacturer}
            helperText={errors.pistolManufacturer}
          />

          {selectedLicense.pistolManufacturer === 'Other' && (
            <SmartInput
              label="יצרן אחר"
              value={selectedLicense.otherOption || ''}
              onChange={(e) => updateSelectedLicense('otherOption', e.target.value)}
              required
            />
          )}

          <SmartInput
            label="דגם האקדח - יש לרשום באנגלית בלבד"
            value={selectedLicense.pistolModel || ''}
            onChange={(e) => updateSelectedLicense('pistolModel', e.target.value)}
            required
            error={!!errors.pistolModel}
            helperText={errors.pistolModel}
          />

          <SmartSelect
            label="קוטר קליע/קליבר"
            value={selectedLicense.ammunitionCaliber || ''}
            onChange={(e) => updateSelectedLicense('ammunitionCaliber', e.target.value)}
            options={[
              { value: 'none', label: 'בחר' },
              { value: '9x19', label: '9x19' },
              { value: '9x17', label: '9x17' },
              { value: '7.65browning', label: '7.65 Browning' },
              { value: '22LR', label: '22LR' },
              { value: '22WM', label: '22WM' },
              { value: '45AUTO', label: '45AUTO' },
              { value: '44MAG', label: '44MAG' },
              { value: '20S&W', label: '20S&W' },
              { value: '22SHORT', label: '22SHORT' },
              { value: '6.35', label: '6.35' },
              { value: '38SPL', label: '38SPL' },
              { value: '222REM', label: '222REM' },
              { value: '22HORNET', label: '22HORNET' },
              { value: '50AE', label: '50AE' },
              { value: '32S&WLONG', label: '32S&WLONG' },
              { value: '357MAG', label: '357MAG' },
              { value: '38S&W', label: '38S&W' },
              { value: '41AE', label: '41AE' },
              { value: '7.63x25', label: '7.63x25' },
              { value: '12Gauge', label: '12Gauge' },
              { value: '5.56x45', label: '5.56x45' },
              { value: 'Other', label: 'אחר - יש לעדכן את המדריך' }
            ]}
            required
            error={!!errors.ammunitionCaliber}
            helperText={errors.ammunitionCaliber}
          />

          <SmartDatePicker
            label="תוקף הרישיון"
            selectedDate={selectedLicense.licenseValidity || null}
            onChange={(date) => updateSelectedLicense('licenseValidity', date)}
            required
            error={!!errors.licenseValidity}
            helperText={errors.licenseValidity}
          />
        </>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
        <Button variant="outlined" onClick={handlePrevious}>
          קודם
        </Button>
        <Button variant="contained" onClick={onNext} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'הבא'}
        </Button>
      </Box>
    </Box>
  );
};

export default LicenseDetails;
