import React, { useState, useRef } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { SmartInput } from './SmartInputSelect';
import SignatureCanvas from 'react-signature-canvas';
import DeleteIcon from '../icons/icons8-delete-90.png';
import '../styles/NewRegister.css';
import '../styles/SignaturePad.css';

const Confirmation = ({ formData, setFormData, handleFinalSubmit, handlePrevious, text, language }) => {
    const [errors, setErrors] = useState({});
    const sigCanvas = useRef({});

    const validate = () => {
        let newErrors = {};

        if (!formData.street.trim()) {
            newErrors.street = 'יש למלא את שם הרחוב';
        }
        if (!formData.homeNumber.trim()) {
            newErrors.homeNumber = 'יש למלא את מספר הבית';
        }
        if (!formData.city.trim()) {
            newErrors.city = 'יש למלא את שם העיר';
        }

        if (sigCanvas.current.isEmpty()) {
            newErrors.signature = 'יש לחתום';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onFinalSubmit = () => {
        if (validate()) {
            const signatureData = sigCanvas.current.toDataURL('image/png');
            handleFinalSubmit(signatureData);
        }
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }} dir="rtl">
            <div className="chrome-tab">
                <p>
                    {formData.registrationType} - {formData.fullName ? formData.fullName + " - " : " "} {formData.teudatZehut}
                </p>
            </div>
            <Box sx={{ width: '100%', mb: 3, p: 2, border: "1px solid #ccc", borderRadius: "5px" }}>
                <p className="register-subheading">יש להצהיר על כתובת מגורים הנוכחית</p>
                <SmartInput
                    label={text[language].street}
                    value={formData.street}
                    onChange={(e) => setFormData({ ...formData, street: e.target.value })}
                    required
                    error={!!errors.street}
                    helperText={errors.street}
                />
                <SmartInput
                    label={text[language].homeNumber}
                    value={formData.homeNumber}
                    onChange={(e) => setFormData({ ...formData, homeNumber: e.target.value })}
                    required
                    error={!!errors.homeNumber}
                    helperText={errors.homeNumber}
                />
                <SmartInput
                    label={text[language].city}
                    value={formData.city}
                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                    required
                    error={!!errors.city}
                    helperText={errors.city}
                />
                <SmartInput
                    label={`${text[language].zip} (אופציונלי)`}
                    value={formData.zip}
                    onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
                />
            </Box>

            <Box sx={{ width: '100%', mb: 3, p: 2, border: "1px solid #ccc", borderRadius: "5px" }}>
                <p className="register-subheading">חתימה</p>
                <p className="signature-instruction">בחתימה זאת אני מצהיר/ה על כך שהמידע שמילאתי נכון ומדויק</p>
                <Box className="signature-container">
                    <SignatureCanvas
                        penColor='#4e84e9'
                        dotSize={2}
                        minWidth={2}
                        maxWidth={4}
                        throttle={16}
                        canvasProps={{
                            className: 'sigCanvas',
                            style: {
                                touchAction: 'none',
                            }
                        }}
                        ref={sigCanvas}
                    />
                    {errors.signature && (
                        <p className="error-text">{errors.signature}</p>
                    )}
                    <Button 
                        onClick={clearSignature}
                        variant="outlined"
                        color="warning"
                        size="small"
                        sx={{ mt: 1 }}
                    >
                        נקה חתימה
                        <img className="delete-icon" src={DeleteIcon} alt="delete" style={{ marginRight: '8px' }} />
                    </Button>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                <Button variant="outlined" onClick={handlePrevious}>
                    חזור
                </Button>
                <Button variant="contained" onClick={onFinalSubmit} color="primary">
                    הירשם
                </Button>
            </Box>
        </Box>
    );
};

export default Confirmation;
