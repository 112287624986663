import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
// Import the new CSS file
import '../styles/NewRegister.css';
// Import SmartInput and SmartSelect
import { SmartInput, SmartSelect } from './SmartInputSelect';

// The validateTeudatZehut function ensures the ID is exactly 9 digits and follows the checksum rule.
const validateTeudatZehut = (id) => {
  if (id.length !== 9 || isNaN(id)) return false;
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    let digit = parseInt(id[i], 10);
    let weight = i % 2 === 0 ? 1 : 2;
    let product = digit * weight;
    sum += product > 9 ? product - 9 : product;
  }
  return sum % 10 === 0;
};

const IDAndTrainType = ({ formData, setFormData, handleNext, handlePrevious }) => {
  const [errors, setErrors] = useState({ teudatZehut: '', registrationType: '' });

  //roll to the page top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const validateFields = (field, value) => {
    let teudatZehutError = errors.teudatZehut;
    let registrationTypeError = errors.registrationType;

    // Check honeypot field
    if (formData.contact_me_by_fax) {
      return false; // Silent fail if honeypot is filled
    }

    if (field === 'teudatZehut') {
      if (!value.trim()) {
        teudatZehutError = 'נא למלא את תעודת הזהות';
      } else if (!validateTeudatZehut(value.trim())) {
        teudatZehutError = 'תעודת זהות לא תקינה';
      } else {
        teudatZehutError = '';
      }
    }

    if (field === 'registrationType') {
      if (!value || value === "" || value === "מיידית") {
        registrationTypeError = 'נא לבחור סוג אימון';
      } else {
        registrationTypeError = '';
      }
    }

    setErrors({ teudatZehut: teudatZehutError, registrationType: registrationTypeError });

    return !teudatZehutError && !registrationTypeError;
  };

  // Called when user clicks "Next"
  const onNext = () => {
    if (validateFields('teudatZehut', formData.teudatZehut) && validateFields('registrationType', formData.registrationType)) {
      handleNext();
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }} dir="rtl">
      {/* Honeypot field - hidden from real users but visible to bots */}
      <div style={{ opacity: 0, position: 'absolute', top: 0, left: 0, height: 0, width: 0, zIndex: -1 }}>
        <input
          type="text"
          name="contact_me_by_fax"
          id="contact_me_by_fax"
          value={formData.contact_me_by_fax || ''}
          onChange={(e) => setFormData({ ...formData, contact_me_by_fax: e.target.value })}
          tabIndex="-1"
          autoComplete="off"
        />
      </div>
      <SmartInput
        label="תעודת זהות"
        value={formData.teudatZehut}
        onChange={(e) => {
          setFormData({ ...formData, teudatZehut: e.target.value });
          validateFields('teudatZehut', e.target.value);
        }}
        required
        error={!!errors.teudatZehut}
        helperText={errors.teudatZehut}
      />
      <SmartSelect
        label="סוג אימון"
        value={formData.registrationType}
        onChange={(e) => {
          setFormData({ ...formData, registrationType: e.target.value });
          validateFields('registrationType', e.target.value);
        }}
        options={[
          { value: '', label: 'בחר אימון' },
          { value: 'הכשרה ראשונית', label: 'הכשרה ראשונית' },
          { value: 'הכשרה מקוצרת', label: 'הכשרה מקוצרת' },
          { value: 'רענון תקופתי', label: 'רענון תקופתי אזרחי' },
          { value: 'חידוש רישיון', label: 'חידוש רישיון אזרחי' },
          { value: 'ירי חופשי', label: 'ירי השתפרות - "חופשי"' },
          { value: 'הכשרה ארגונית', label: 'הכשרה ארגונית - מאבטח 9 שעות' },
          { value: 'רענון ארגוני', label: 'רענון ארגוני - מאבטח 4.5 שעות' },
          { value: 'מפעל ראוי', label: 'מפעל ראוי' },
          { value: 'אימון החלפה', label: 'אימון החלפה' },
          { value: 'אימון שיוך', label: 'אימון שיוך' },
          { value: 'השלמת מכסת תחמושת', label: 'השלמת מכסת תחמושת' },
          { value: 'בדיקת תקינות נשק', label: 'בדיקת תקינות נשק' }
        ]}
        required
        error={!!errors.registrationType}
        helperText={errors.registrationType}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
        <Button variant="outlined" onClick={handlePrevious}>
          קודם
        </Button>
        <Button variant="contained" onClick={onNext}>
          הבא
        </Button>
      </Box>
    </Box>
  );
};

export default IDAndTrainType;
