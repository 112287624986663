import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { SmartSelect } from './SmartInputSelect';
import '../styles/NewRegister.css';

const RegistrationTiming = ({ formData, setFormData, handleNext }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [isFutureDate, setIsFutureDate] = useState(false);
  const [error, setError] = useState('');
  const [dateOptions, setDateOptions] = useState([]);

  useEffect(() => {
    generateDateOptions();
  }, []);

  useEffect(() => {
    console.log(selectedDate, formData.futureDate);
    if (selectedDate && formData.futureDate === selectedDate) {
      handleNext(); // only trigger after futureDate is confirmed saved
    }
  }, [formData.futureDate]);

  const isDateSelectable = (date) => {
    const day = date.getDay();
    return day !== 6; // Exclude Saturdays
  };

  const generateDateOptions = () => {
    const dates = [];
    const today = new Date();
    const daysOfWeek = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];

    for (let i = 1; i <= 21; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      if (isDateSelectable(date)) {
        const dayOfWeek = daysOfWeek[date.getDay()];
        const formattedDate = `${dayOfWeek} - ${date.toLocaleDateString('he-IL')}`;
        dates.push({ value: date.toISOString().split('T')[0], label: formattedDate });
      }
    }
    setDateOptions(dates);
  };

  const handleFutureDateSubmit = () => {
    if (!selectedDate) {
      setError('יש לבחור תאריך');
      return;
    }

    // Clear any error messages
    setError('');
    // Update parent's formData with the future date
    setFormData({
      ...formData,
      futureDate: selectedDate,
    });
    console.log('FormData future Date updated:', formData.futureDate);
    // Proceed to the next section
    // handleNext();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }} dir="rtl">
      <h2 className="register-heading">ברוכים הבאים<br/> למטווח שי לוי - קרני שומרון</h2>
      <h6 className="register-subheading" style={{ fontSize: '1rem', '@media (max-width: 600px)': { fontSize: '0.8rem' } }}>
        אנא מלאו את הטופס בעברית בלבד - אם הינכם זקוקים לעזרה אנא פנו לצוות שלנו ונשמח לעזור!
      </h6>
      <Box sx={{ mb: 2, display: 'flex', gap: 2 }} className="nav-buttons">
        <Button
          variant="contained"
          onClick={() => {
            setIsFutureDate(false);
            setFormData({ ...formData, futureDate: '' });
            handleNext();
          }}
          className="form-button"
        >
          אני במטווח - התחל בהרשמה
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setIsFutureDate(true);
          }}
          className="form-button"
        >
          הרשמה לתאריך עתידי
        </Button>
      </Box>
      {isFutureDate && (
        <Box sx={{ display: 'flex', gap: 2, mb: 2, width: '100%' }}>
          <SmartSelect
            label="תאריך"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            options={dateOptions}
            required
          />
        </Box>
      )}
      {error && (
        <Box sx={{ color: 'red', mb: 2 }}>
          {error}
        </Box>
      )}
      {isFutureDate && (
        <Button variant="contained" onClick={handleFutureDateSubmit} className="form-button">
          הבא
        </Button>
      )}
    </Box>
  );
};

export default RegistrationTiming;
