import React, { useState } from 'react';
import './SmartInputSelect.css';

export const SmartInput = ({ label, value, onChange, required, error, helperText }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={`smart-input-container ${isFocused || value ? 'active' : ''} ${error ? 'error' : ''}`}>
      <fieldset className="smart-fieldset">
        <legend className="smart-legend">{required && '*'}{label}</legend>
        <input
          type="text"
          className="smart-input"
          value={value}
          onChange={onChange}
          required={required}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </fieldset>
      {error && <p className="helper-text">{helperText}</p>}
    </div>
  );
};

export const SmartSelect = ({ label, value, onChange, options, required, error, helperText }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={`smart-input-container ${isFocused || value ? 'active' : ''} ${error ? 'error' : ''}`}>
      <fieldset className="smart-fieldset">
        <legend className="smart-legend">{required && '*'}{label}</legend>
        <select
          className="smart-select"
          value={value}
          onChange={onChange}
          required={required}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        >
          <option value=""></option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </fieldset>
      {error && <p className="helper-text">{helperText}</p>}
    </div>
  );
};



export const SmartDatePicker = ({
    label,
  selectedDate,
  onChange,
  required,
  error,
  helperText,
  minYear = 2020,
  maxYear = 2030,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const today = new Date();

  // If selectedDate is given, parse it; otherwise default to today's date
  const initialDate = selectedDate ? new Date(selectedDate) : today;

  const [currentMonth, setCurrentMonth] = useState(initialDate.getMonth());
  const [currentYear, setCurrentYear] = useState(initialDate.getFullYear());

  // Hebrew day names
  const dayNames = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז'];

  // Hebrew month names
  const monthOptions = [
    { value: 0, label: 'ינואר' },
    { value: 1, label: 'פברואר' },
    { value: 2, label: 'מרץ' },
    { value: 3, label: 'אפריל' },
    { value: 4, label: 'מאי' },
    { value: 5, label: 'יוני' },
    { value: 6, label: 'יולי' },
    { value: 7, label: 'אוגוסט' },
    { value: 8, label: 'ספטמבר' },
    { value: 9, label: 'אוקטובר' },
    { value: 10, label: 'נובמבר' },
    { value: 11, label: 'דצמבר' },
  ];

  // Build an array of years between minYear & maxYear
  const yearOptions = [];
  for (let y = minYear; y <= maxYear; y++) {
    yearOptions.push({ value: y, label: y });
  }

  // Generate the full calendar grid, including overlapping days
  const generateCalendarGrid = () => {
    const grid = [];
    const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDayWeekday = new Date(currentYear, currentMonth, 1).getDay(); // 0 -> Sunday, 6 -> Saturday

    // SHIFT so that Sunday=0, Monday=1, ... Saturday=6
    const shift = firstDayWeekday;
    const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const prevYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    const daysInPrevMonth = new Date(prevYear, prevMonth + 1, 0).getDate();

    // Overlapping days from previous month
    for (let i = 0; i < shift; i++) {
      const day = daysInPrevMonth - (shift - 1) + i;
      grid.push({
        day,
        inCurrentMonth: false,
        date: new Date(prevYear, prevMonth, day),
      });
    }

    // Days of the current month
    for (let d = 1; d <= daysInCurrentMonth; d++) {
      grid.push({
        day: d,
        inCurrentMonth: true,
        date: new Date(currentYear, currentMonth, d),
      });
    }

    // Next month overlap
    const totalCells = Math.ceil(grid.length / 7) * 7;
    const nextDays = totalCells - grid.length;
    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
    const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
    for (let d = 1; d <= nextDays; d++) {
      grid.push({
        day: d,
        inCurrentMonth: false,
        date: new Date(nextYear, nextMonth, d),
      });
    }

    return grid;
  };

  const calendarDays = generateCalendarGrid();

  // Handler for day click
  const handleDayClick = (dateObj) => {
    onChange(dateObj);
  };

  const isSameDay = (dateA, dateB) => {
    return (
      dateA.getFullYear() === dateB.getFullYear() &&
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getDate() === dateB.getDate()
    );
  };

  return (
    <div
      className={`dark-date-picker-container ${isFocused || selectedDate ? 'active' : ''} ${
        error ? 'error' : ''
      }`}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <div className="dark-date-picker-header">
        <label className="date-smart-legend">{required && '*'}{label}</label>
        {/* Month Select */}
        <select
          className="dark-date-picker-select"
          value={currentMonth}
          onChange={(e) => setCurrentMonth(parseInt(e.target.value, 10))}
        >
          {monthOptions.map((m) => (
            <option key={m.value} value={m.value}>
              {m.label}
            </option>
          ))}
        </select>

        {/* Year Select */}
        <select
          className="dark-date-picker-select"
          value={currentYear}
          onChange={(e) => setCurrentYear(parseInt(e.target.value, 10))}
        >
          {yearOptions.map((y) => (
            <option key={y.value} value={y.value}>
              {y.label}
            </option>
          ))}
        </select>
      </div>

      <div className="dark-date-picker-grid">
        {/* Day Names Header */}
        {dayNames.map((dn, idx) => (
          <div key={idx} className="dark-date-picker-day-name">
            {dn}
          </div>
        ))}
        {/* Calendar Days */}
        {calendarDays.map((dayObj, idx) => {
          const isSelected =
            selectedDate && isSameDay(new Date(selectedDate), dayObj.date);
          return (
            <div
              key={idx}
              className={`dark-date-picker-day ${
                isSelected ? 'selected' : ''
              } ${dayObj.inCurrentMonth ? '' : 'not-current'}`}
              onClick={() => handleDayClick(dayObj.date)}
            >
              {dayObj.day}
            </div>
          );
        })}
      </div>

      {/* Display chosen date (optional) */}
      {selectedDate && (
        <p className="dark-date-selected">
          נבחר: {new Date(selectedDate).toLocaleDateString('he-IL')}
        </p>
      )}

      {/* Show errors if needed */}
      {error && <p className="helper-text">{helperText}</p>}
    </div>
  );
};


// SmartCheckbox Component
export const SmartCheckbox = ({ label, checked, onChange, required, error, helperText }) => {
  return (
    <div className={`smart-checkbox-container ${error ? 'error' : ''}`}>
      <label className="smart-checkbox-label">
        <input
          type="checkbox"
          className="smart-checkbox"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          required={required}
        />
        {label}
      </label>
      {error && <p className="helper-text">{helperText}</p>}
    </div>
  );
};

