import React, { useState } from 'react';
import RegistrationTiming from './RegistrationTiming';
import IDAndTrainType from './IDAndTrainType';
import PersonalInformation from './PersonalInformation';
import LicenseDetails from './LicenseDetails';
import FileUpload from './FileUpload';
import Confirmation from './Confirmation';
import CircularProgress from '@mui/material/CircularProgress';
// Firebase & helper imports
import { db, generateRangeId, addNonLoggedMember, addRegistration, addSubscriber } from '../components/firebase.js';
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
// Import the new CSS file
import '../styles/NewRegister.css';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NewRegister = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    rangeId: '',
    registrationType: '',
    fullName: '',
    phoneNumber: '',
    teudatZehut: '',
    gunLicenseId: '',
    imprintOnPistol: '',
    licenseValidity: '',
    email: '',
    conditionalApprovalFileUrl: '',
    healthDeclarationConfirm: false,
    healthUpdateConfirm: false,
    newsletter: false,
    street: '',
    homeNumber: '',
    city: '',
    zip: '',
    pistolManufacturer: '',
    pistolModel: '',
    otherOption: '',
    quizResult: 'טרם נבחן',
    allowEmailCommunication: false,
    inWallSafeCommitment: false,
    futureDate: '',
    licenses: [],
    selectedLicenseIndex: 0,
    // registrationDate: new Date().toLocaleDateString('he-IL', { timeZone: 'Asia/Jerusalem' }),
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  // Example text and language values for Hebrew labels/messages
  const text = {
    he: {
      fullName: 'שם מלא',
      phoneNumber: 'מספר טלפון',
      gunLicenseId: 'מספר רישיון',
      imprintOnPistol: 'טביעת אקדח',
      confirmCommitment: 'אני מאשר את התחייבות התקנת כספת',
      street: 'רחוב',
      homeNumber: 'מספר בית',
      city: 'עיר',
      zip: 'מיקוד',
      registrationSuccess: 'ההרשמה בוצעה בהצלחה!',
      registrationSuccessMessage: ` היי ${formData.fullName} נרשמת ל${formData.registrationType} בהצלחה`,
      signatureRequired: 'חתימה נדרשת',
      HealthDeclarationRequired: 'יש לאשר את הצהרת הבריאות'
    }
  };
  const language = 'he';

  // Navigation callbacks for stepping between sections.
  const handleNext = async () => {
    setLoading(true);
    console.log(`handleNext called, currentStep: ${currentStep} and futureDate: ${formData.futureDate}`);
    setMessage('אנא המתינו לטעינת הנתונים');

    if (currentStep === 1) {
      try {
        const usersCollectionRef = collection(db, 'users');
        const q = query(usersCollectionRef, where("teudatZehut", "==", formData.teudatZehut));
        const querySnapshot = await getDocs(q);
    
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userData = userDoc.data();
          const userDocRef = userDoc.ref;
    
          let licenses = userData.licenses || [];
    
          // Flag for detecting if migration is needed
          let shouldUpdateFirestore = false;
    
          // Case: licenses is empty, but old fields exist
          if (
            licenses.length === 0 &&
            userData.gunLicenseId &&
            userData.pistolModel &&
            userData.imprintOnPistol &&
            userData.licenseValidity &&
            userData.pistolManufacturer
          ) {
            const licenseEntry = {
              gunLicenseId: userData.gunLicenseId,
              pistolModel: userData.pistolModel,
              imprintOnPistol: userData.imprintOnPistol,
              licenseValidity: userData.licenseValidity,
              pistolManufacturer: userData.pistolManufacturer,
              ammunitionCaliber: userData.ammunitionCaliber || 'none',
              otherOption: userData.otherOption || ''
            };
    
            licenses.push(licenseEntry);
            shouldUpdateFirestore = true;
          }
    
          // Update Firestore with new licenses[] if needed
          if (shouldUpdateFirestore) {
            await setDoc(userDocRef, { licenses }, { merge: true });
            console.log('User document updated with licenses[] in Firestore.');
          }
    
          // Update local state
          setFormData((prevData) => ({
            ...prevData,
            ...userData,
            licenses,
            selectedLicenseIndex: 0,
            registrationType: prevData.registrationType,
            futureDate: prevData.futureDate || '' 
          }));
        }
      } catch (error) {
        console.error('Error fetching or updating user data:', error);
      }
    }

    if (currentStep === 3) {
      await saveUserLicenses();
    }
    
    setCurrentStep(prev => prev + 1);
    window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0; // For most browsers
    document.body.scrollTop = 0; // For Safari
    setLoading(false);
    setMessage('');
  };

  const handlePrevious = () => {
    setLoading(true);
    setCurrentStep(prev => prev - 1);
    window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0; // For most browsers
    document.body.scrollTop = 0; // For Safari
    setLoading(false);
    setMessage('');
  };

  // Final submission: called from the last subcomponent.
  const handleFinalSubmit = async (signatureData) => {
    setLoading(true);
    setMessage('נא לא לסגור את החלון עד לקבלת הודעת הסיום - מנפיק מסמכים עבורך');

    if (formData.isLoading) return;

    try {
      const currentDate = formData.futureDate || new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
      console.log('Current Date:', currentDate);
      const dailyUsersRef = doc(db, 'daily_users', currentDate);
      const dailyUsersSnap = await getDoc(dailyUsersRef);
      const dailyUserData = dailyUsersSnap.exists() ? dailyUsersSnap.data() : {};

      let rangeId;
      let updatedFormData;
      let isUnique = false;
      let attempts = 0;
      while (!isUnique && attempts < 3) {
          rangeId = await generateRangeId();
          updatedFormData = prepareFormData(signatureData, rangeId);
          if (!dailyUserData[rangeId] || dailyUserData[rangeId].teudatZehut === formData.teudatZehut) {
        isUnique = true;
          }
          attempts++;
      }

      if (!isUnique) {
          throw new Error('Failed to generate a unique range ID after 3 attempts');
      }

      // Save the user data first
      await saveUserData(updatedFormData);
      dailyUserData[rangeId] = { ...updatedFormData };
      delete dailyUserData[rangeId].signatureUrl; // Remove the signatureUrl field

      await setDoc(dailyUsersRef, dailyUserData, { merge: true });
      console.log('Data saved to daily_users successfully.');


      setCurrentStep(6); // Move to the success step
    } catch (error) {
      console.error('Error during final submission:', error);
      handleError(error);
      setCurrentStep(6); // Move to the failure step
    } finally {
      setLoading(false);
      setFormData((prevData) => ({ ...prevData, isLoading: false }));
      setMessage('');
    }

    
  };


  const handleError = (error) => {
    console.error('Error during registration:', error);
    alert('Registration failed. Please try again.');
  };

  
  const prepareFormData = (signatureData, rangeId) => {
    const licenseEntry = {
      gunLicenseId: formData.gunLicenseId,
      imprintOnPistol: formData.imprintOnPistol,
      licenseValidity: formData.licenseValidity,
      pistolManufacturer: formData.pistolManufacturer === 'Other' ? formData.otherOption : formData.pistolManufacturer,
      pistolModel: formData.pistolModel,
      ammunitionCaliber: formData.ammunitionCaliber
    };
    
    
    return {
      ...formData,
      rangeId,
      registrationDate: formData.futureDate !== '' 
        ? new Date(formData.futureDate).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' }) 
        : new Date().toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' }),
      signatureUrl: signatureData,
      status: 'ממתין לאישור',
      allowEmailCommunication: formData.email !== '',
      bulletCount: formData.registrationType === 'הכשרה ראשונית' 
        ? 100 
        : formData.registrationType === 'השלמת מכסת תחמושת' 
          ? 0 
          : 50,
      homeNumber: formData.homeNumber || '',
      zip: formData.zip || '',
      street: formData.street || '',
      city: formData.city || '',
      phoneNumber: formData.phoneNumber || '',
      email: formData.email || '',
      licenses: [licenseEntry]
    };
  };

  const saveUserLicenses = async () => {
    try {
      const q = query(collection(db, 'users'), where("teudatZehut", "==", formData.teudatZehut));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userDocRef = userDoc.ref;
        const userData = userDoc.data();
        const existingLicenses = userData.licenses || [];
  
        const newLicenses = formData.licenses.filter((lic) => {
          return !existingLicenses.some(existing =>
            existing.gunLicenseId === lic.gunLicenseId &&
            existing.imprintOnPistol === lic.imprintOnPistol &&
            existing.pistolModel === lic.pistolModel
          );
        });
  
        if (newLicenses.length > 0) {
          const updatedLicenses = [...existingLicenses, ...newLicenses];
          await setDoc(userDocRef, { licenses: updatedLicenses }, { merge: true });
          console.log(`Saved ${newLicenses.length} new license(s) after step 3.`);
        }
      }
    } catch (error) {
      console.error('Error saving licenses after LicenseDetails step:', error);
    }
  };

  
  

  const saveUserData = async (data) => {
    const dateKey = await addRegistration(data.rangeId, data);
  
    if (data.newsletter) {
      await addSubscriber({
        email: data.email,
        name: data.fullName,
        mobile: data.phoneNumber,
      });
    }
  
    try {
      const usersCollectionRef = collection(db, 'users');
      const q = query(usersCollectionRef, where("teudatZehut", "==", data.teudatZehut));
      const querySnapshot = await getDocs(q);
  
      const selectedLicense = data.licenses?.[0]; // We're always saving with one selected license
  
      if (!querySnapshot.empty && selectedLicense) {
        const userDoc = querySnapshot.docs[0];
        const userDocRef = userDoc.ref;
        const userData = userDoc.data();
        const existingLicenses = userData.licenses || [];
  
        // Check if this license already exists (by gunLicenseId and pistolModel — or use more fields for strictness)
        const isNewLicense = !existingLicenses.some(lic =>
          lic.gunLicenseId === selectedLicense.gunLicenseId &&
          lic.pistolModel === selectedLicense.pistolModel &&
          lic.imprintOnPistol === selectedLicense.imprintOnPistol
        );
  
        if (isNewLicense) {
          const updatedLicenses = [...existingLicenses, selectedLicense];
          await setDoc(userDocRef, { licenses: updatedLicenses }, { merge: true });
          console.log('New license added to user document in Firestore');
        } else {
          console.log('License already exists, not adding duplicate.');
        }
      } else if (selectedLicense) {
        // First time user, create new document
        const userDocRef = doc(db, 'users', data.teudatZehut); // You can also use UID or auto ID
        await setDoc(userDocRef, {
          ...data,
          licenses: [selectedLicense],
        }, { merge: true });
        console.log('New user created with license.');
      }
  
    } catch (err) {
      console.error('Failed to save user license:', err);
    }
  
    // Save to daily_users
    await addNonLoggedMember(data, dateKey);
  };
  

  // Render the appropriate section based on the current step.
  const renderStep = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
          <p>{message}</p>
        </Box>
      );
    }

    switch (currentStep) {
      case 0:
        return (
          <RegistrationTiming 
            formData={formData} 
            setFormData={setFormData} 
            handleNext={handleNext} 
          />
        );
      case 1:
        return (
          <IDAndTrainType 
            formData={formData} 
            setFormData={setFormData} 
            handleNext={handleNext} 
            handlePrevious={handlePrevious} 
          />
        );
      case 2:
        return (
          <PersonalInformation 
            formData={formData} 
            setFormData={setFormData} 
            handleNext={handleNext} 
            handlePrevious={handlePrevious} 
          />
        );
      case 3:
        return (
          <LicenseDetails 
            formData={formData} 
            setFormData={setFormData} 
            handleNext={handleNext} 
            handlePrevious={handlePrevious} 
            text={text} 
            language={language} 
          />
        );
      case 4:
        return (
          <FileUpload 
            formData={formData} 
            setFormData={setFormData} 
            handleNext={handleNext} 
            handlePrevious={handlePrevious} 
            text={text} 
            language={language} 
          />
        );
      case 5:
        return (
          <Confirmation 
            formData={formData} 
            setFormData={setFormData} 
            handleFinalSubmit={handleFinalSubmit} 
            handlePrevious={handlePrevious} 
            text={text} 
            language={language} 
          />
        );
      case 6:
        return (
          <div>
            <h2>{text[language].registrationSuccess}</h2>
            <p>{text[language].registrationSuccessMessage}</p>
            <button onClick={() => navigate('/')}>מעבר לעמוד הבית </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="new-register-container">
      {renderStep()}
    </div>
  );
};

export default NewRegister;
