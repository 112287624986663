import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import text from '../../text';

function formatHebrewText(text) {
  let parts = text.split(/(\d\.\s)/).filter(part => part.trim() !== '');

  let header = parts.shift();

  let formattedText = parts.reduce((acc, part, index) => {
    if (index % 2 === 0) {
      return acc + `<p>${part.trim()}`;
    } else {
      return acc + part.trim() + `</p>`;
    }
  }, `<p>${header.trim()}</p>`);

  return formattedText;
}

const InWallSafe = ({ formData, handleChange }) => {
  const { language } = useLanguage();

  return (
    <div className="in-wall-safe">
      <h3 style={{borderBottom:'2px solid #ccc ', width:'90%'}}>התחייבות מקבל/ת רישיון להתקנת כספת</h3>
      <div>
        <p>אני, {formData.fullName}, ת.ז {formData.teudatZehut}, מתחייב כי:</p>
        <p>1. ידוע לי כי בהתאם להנחיות אגף לרישוי ופיקוח כלי ירייה במשרד לביטחון לאומי, המפורסמות באתר המשרד, עליי לאחסן את כלי הירייה הרשום ברישיון שאקבל בכספת שתותקן בביתי.</p>
        <p>2. הכספת תותקן במקום מוסתר המצוי בשליטתי ואחריותי.</p>
        <p>3. אני מתחייב/ת כי הכספת תשמש לאחסון כלי ירייה הנמצא בחזקתי בלבד, באופן שלא יתאפשר לאדם אחר, לרבות ליתר דיירי הבית, גישה אל כלי הירייה.</p>
        <p>4. ידוע לי כי הפרת התחייבות זו כמוה כהפרת תנאי רישיון להחזקת כלי ירייה, על כל המשתמע.</p>
      </div>
      <label className='checkbox-row'>
        <p style={{width:'100%'}}>{text[language].confirmCommitment}</p>
        <input
          type="checkbox"
          name="inWallSafeCommitment"
          checked={formData.inWallSafeCommitment}
          onChange={handleChange}
          required
        />
      </label>
    </div>
  );
};

export default InWallSafe;
