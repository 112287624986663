import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, setDoc , deleteDoc} from 'firebase/firestore';
import { db } from './firebase';
import '../styles/ShooterLog.css';
import TransactionList from './TransactionList';
import { CSVLink } from 'react-csv';
import ClientManagementPopup from './ClientManagementPopup';
import { Collapse, Button } from '@mui/material';
// import { FileText } from 'lucide-react';

const ShootingLedger = () => {
    const [ledgerEntries, setLedgerEntries] = useState([]);
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState('all');
    const [selectedType, setSelectedType] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedClient, setSelectedClient] = useState(null);
    const [totalBulletCount, setTotalBulletCount] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [bulletCountByType, setBulletCountByType] = useState({});
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [years, setYears] = useState([]);
    const [statsOpen, setStatsOpen] = useState(false);
    // Additional state variables for start and end dates
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [filteredDeleted, setFilteredDeleted] = useState([]);

    useEffect(() => {
        fetchDates();
    }, []);

    useEffect(() => {
        console.log("Fetching ledger Entries")
        fetchLedgerEntries();
    }, [selectedDate, selectedType, selectedYear, startDate, endDate, selectedStatus]);

    useEffect(() => {
        applyFilters();
    }, [searchQuery, ledgerEntries]);

    const fetchDates = async () => {
        try {
            const snapshot = await getDocs(collection(db, 'daily_users'));
            const uniqueDates = snapshot.docs.map(doc => doc.id).sort().reverse();
            const uniqueYears = [...new Set(uniqueDates.map(date => date.split('-')[0]))].sort().reverse();
            setDates(uniqueDates);
            setYears(uniqueYears);
        } catch (error) {
            console.error('Error fetching dates:', error);
        }
    };

    

    const fetchLedgerEntries = async () => {
        try {
            setIsLoading(true);
            const snapshot = await getDocs(collection(db, 'daily_users'));
            const allEntries = [];
            let bulletCount = 0;
            const bulletCountByTypeTemp = {};
    
            snapshot.forEach((docSnap) => {
                const docDate = docSnap.id;
                const year = docDate.split('-')[0];
    
                const inYear = year === selectedYear.toString();
                const afterStartDate = startDate ? docDate >= startDate : true;
                const beforeEndDate = endDate ? docDate <= endDate : true;
                const inSelectedDate = selectedDate === 'all' || docDate === selectedDate;
    
                if (inYear && afterStartDate && beforeEndDate && inSelectedDate) {
                    const data = docSnap.data();
                    Object.entries(data).forEach(([rangeId, entry]) => {
                        if ((selectedType === 'all' || entry.registrationType === selectedType) &&
                            (selectedStatus === 'all' || entry.status === selectedStatus) &&
                            !filteredDeleted.includes(rangeId)) {
                            allEntries.push({ rangeId, ...entry, dateKey: docDate });
                            bulletCount += parseInt(entry.bulletCount) || 0;
                            if (!bulletCountByTypeTemp[entry.registrationType]) {
                                bulletCountByTypeTemp[entry.registrationType] = 0;
                            }
                            bulletCountByTypeTemp[entry.registrationType] += parseInt(entry.bulletCount) || 0;
                        }
                    });
                }
            });
    
            const sortedEntries = allEntries.sort((a, b) => b.rangeId - a.rangeId);
            setLedgerEntries(sortedEntries);
            setFilteredEntries(sortedEntries);
            setTotalBulletCount(bulletCount);
            setBulletCountByType(bulletCountByTypeTemp);
            setTotalUsers(sortedEntries.length);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching ledger entries:', error);
            setIsLoading(false);
        }
    };

    const resetFilters = () => {
        setSearchQuery('');
        setSelectedType('all');
        setSelectedDate('all');
        setStartDate('');
        setEndDate('');
        fetchLedgerEntries();
    };

    const applyFilters = () => {
        const filtered = ledgerEntries.filter(
            (entry) =>
                (searchQuery === '' ||
                    entry.phoneNumber.includes(searchQuery) ||
                    entry.teudatZehut.includes(searchQuery)) &&
                (selectedType === 'all' || entry.registrationType === selectedType) &&
                (selectedStatus === 'all' || entry.status === selectedStatus)
        );
        setFilteredEntries(filtered);
    };

    const applyDateFilter = () => {
        if (startDate === '' || endDate === '') {
            alert('יש למלא תאריכי התחלה וסיום');            
            return;
        }
        fetchLedgerEntries();
    };

    const downloadCSV = (entries) => {
        return entries.map((entry) => ({
            'מספר': entry.rangeId,
            'תאריך': entry.registrationDate.split(',')[0],
            'שעה': entry.registrationDate.split(',')[1],
            'שם ומשפחה': entry.fullName,
            'תז': entry.teudatZehut,
            'סיבת הירי': entry.registrationType,
            'הצהרת בריאות': entry.healthDeclarationConfirm ? '✔' : '',
            'תוקף רישיון': entry.licenseValidity,
            'סוג כלי היריה': entry.registrationType === 'הכשרה ראשונית' ? 'גלוק' : entry.pistolManufacturer,
            'בעלות': entry.registrationType === 'הכשרה ראשונית' ? 'מטווח' : 'פרטי',
            'מס כלי': entry.imprintOnPistol,
            'קליבר': entry.registrationType === 'הכשרה ראשונית' ? '9x19' : entry.ammunitionCaliber,
            'כמות': entry.bulletCount,
            'שעת סיום האימון': entry.trainingEndTime,
            'חתימת המאמן': entry.verifiedBy,
            'סטטוס': entry.status,
        }));
    };

    const handleRangeIdClick = (entry) => {
        setSelectedClient(entry);
    };

    const closePopup = () => {
        setSelectedClient(null);
        
    };

    const updateClient = (updatedClient) => {
        const updatedEntries = ledgerEntries.map((entry) =>
            entry.rangeId === updatedClient.rangeId ? updatedClient : entry
        );
        setLedgerEntries(updatedEntries);
        applyFilters();
    };

    const handleDeleteCanceledUser = async (entry) => {
        const confirmDelete = window.confirm(`האם אתה בטוח שברצונך להעביר את המשתמש ${entry.fullName} לארכיון?`);
        if (confirmDelete) {
            try {
                console.log('Archiving entry:', entry);
                setFilteredDeleted([...filteredDeleted, entry.rangeId]);

                // Reference to archive collection under the dateKey
                const archiveRef = doc(db, 'archive_users', entry.dateKey);

                // Fetch existing archived users
                const archiveSnap = await getDoc(archiveRef);
                let archivedData = archiveSnap.exists() ? archiveSnap.data() : {};

                // Add the user to the archived list
                archivedData[entry.rangeId] = entry;

                await setDoc(archiveRef, archivedData, { merge: true });

                // Now delete from daily_users by modifying the stored document
                const dailyUsersRef = doc(db, 'daily_users', entry.dateKey);
                const dailyUsersSnap = await getDoc(dailyUsersRef);

                if (dailyUsersSnap.exists()) {
                    console.log('Deleting user from daily_users:', entry);
                    let dailyUsersData = dailyUsersSnap.data();
                    delete dailyUsersData[entry.rangeId];

                    // If there are no more users left under that date, remove the entire document
                    if (Object.keys(dailyUsersData).length === 0) {
                        await deleteDoc(dailyUsersRef);
                    } else {
                        await setDoc(dailyUsersRef, dailyUsersData);
                    }
                }
    
                alert('המשתמש הועבר לארכיון בהצלחה');
                fetchLedgerEntries();
            } catch (error) {
                console.error('Error archiving user:', error);
                alert('שגיאה בהעברת המשתמש לארכיון');
            }
        }
    };
    

    return (
        <div className="table-container">
            <h2>יומן ירי</h2>
            {isLoading && <div className="spinner"></div>}
            <div className="filters-container">
                <div className="filter-group">
                    <label>סינון לפי התאריכים:</label>
                    <div className="date-filters">
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="date-input"
                        />
                        <span>עד</span>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="date-input"
                        />
                    </div>
                
                    <input
                        type="text"
                        placeholder="חפש לפי מספר נייד או תעודת זהות"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                    />
                    <button onClick={applyDateFilter} className="filter-button">החל</button>
                    <button onClick={resetFilters} className="filter-button">איפוס</button>
                    <CSVLink
                    data={downloadCSV(filteredEntries)}
                    filename={`ledger-${selectedDate}.csv`}
                    
                >
                    <button className="csv-button">
                        הורד גיליון
                    </button>
                </CSVLink>
                <button onClick={() => setStatsOpen(!statsOpen)} className="filter-button">
                    {statsOpen ? 'סגור' : 'סקירת תחמושת'}
                </button>
                </div>
                <div className="filter-group">
                    <select value={selectedYear} onChange={(e) => {
                        setSelectedYear(e.target.value);
                        setSelectedDate('all');
                    }}>
                        {years.map((year, index) => (
                            <option key={index} value={year}>{year}</option>
                        ))}
                    </select>
                    <select value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
                        <option value="all">כל התאריכים</option>
                        {dates.filter(date => date.startsWith(selectedYear)).map((date, index) => (
                            <option key={index} value={date}>{date}</option>
                        ))}
                    </select>
                    <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                        <option value="all">כל ההכשרות</option>
                        <option value="הכשרה ראשונית">הכשרה ראשונית</option>
                        <option value="הכשרה מקוצרת">הכשרה מקוצרת</option>
                        <option value="רענון תקופתי">רענון תקופתי אזרחי</option>
                        <option value="חידוש רישיון">חידוש רישיון אזרחי</option>
                        <option value="ירי חופשי">ירי השתפרות - "חופשי"</option>
                        <option value="הכשרה ארגונית">הכשרה ארגונית - מאבטח 9 שעות</option>
                        <option value="רענון ארגוני">רענון ארגוני - מאבטח 4.5 שעות</option>
                        <option value="מפעל ראוי">מפעל ראוי</option>
                        <option value="אימון החלפה">אימון החלפה</option>
                        <option value="אימון שיוך">אימון שיוך</option>
                        <option value="השלמת מכסת תחמושת">השלמת מכסת תחמושת</option>
                        <option value="בדיקת תקינות נשק">בדיקת תקינות נשק</option>
                    </select>
                    <select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                        <option value="all">כל הסטטוסים</option>
                        <option value="מאומת">מאומת</option>
                        <option value="ממתין לאישור">ממתין לאישור</option>
                        <option value="מבוטל">מבוטל</option>
                    </select>
                </div>
                
            </div>

            <div className="actions-container">
                
                
            </div>

        

            <div>
                <Collapse style={{width:"100%"}} in={statsOpen}>
                    <div className="stats-grid">
                        <div className="stat-item">
                            <h3>תחמושת שנורתה</h3>
                            <p>{totalBulletCount} כדורים</p>
                            <p>חבילות {totalBulletCount / 50}</p>
                        </div>
                        {Object.entries(bulletCountByType).map(([type, count]) => (
                            <div className="stat-item" key={type}>
                                <h3>תחמושת עבור {type}</h3>
                                <p>{count} כדורים</p>
                                <p> {count / 50} חבילות</p>
                            </div>
                        ))}
                        <div className="stat-item">
                            <h3>סך הכל נרשמים</h3>
                            <p>{totalUsers}</p>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className="table-overflow">
                <table className="table-table">
                    <thead>
                        <tr>
                            <th colSpan="6">פרטי היורה</th>
                            <th colSpan="5">סיבת הירי</th>
                            <th colSpan="2"></th>
                            <th colSpan="3">פרטי כלי היריה</th>
                            <th colSpan="2">תחמושת שנורתה</th>
                            <th colSpan="2">פרטי האימון</th>
                        </tr>
                        <tr>
                            <th>מספר</th>
                            <th>תאריך</th>
                            <th>שעה</th>
                            <th>שם ומשפחה</th>
                            <th>תז</th>
                            <th>נייד</th>
                            <th>אימון ואימות</th>
                            <th>חידוש רישיון</th>
                            <th>ירי חופשי</th>
                            <th>בדיקת כלי</th>
                            <th>סוג אימון</th>
                            <th>הצהרת בריאות</th>
                            <th>תוקף רישיון</th>
                            <th>סוג</th>
                            <th>בעלות</th>
                            <th>מס' כלי</th>
                            <th>קליבר</th>
                            <th>כמות</th>
                            <th>שעת סיום</th>
                            <th>סטטוס</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredEntries.length > 0 ? (
                            filteredEntries.map((entry, index) => (
                                <tr key={index}>
                                    <td onClick={() => handleRangeIdClick(entry)} className="clickable">
                                        {entry.rangeId}
                                    </td>
                                    <td>{entry.registrationDate.split(',')[0]}</td>
                                    <td>{entry.registrationDate.split(',')[1]}</td>
                                    <td>{entry.fullName}</td>
                                    <td>{entry.teudatZehut}</td>
                                    <td>{entry.phoneNumber}</td>
                                    <td>{['הכשרה ראשונית', 'רענון תקופתי', 'אימון שיוך', 'הכשרה ארגונית', 'מפעל ראוי', 'אימון החלפה'].includes(entry.registrationType) ? '✔' : ''}</td>
                                    <td>{entry.registrationType === 'חידוש רישיון' ? '✔' : ''}</td>
                                    <td>{entry.registrationType === 'ירי חופשי' ? '✔' : ''}</td>
                                    <td>{entry.registrationType === 'בדיקת תקינות נשק' ? '✔' : ''}</td>
                                    <td>{entry.registrationType}</td>
                                    <td>{entry.healthDeclarationConfirm ? '✔' : ''}</td>
                                    <td>{entry.licenseValidity}</td>
                                    <td>{entry.pistolManufacturer || ''}</td>
                                    <td>{entry.registrationType === 'הכשרה ראשונית' ? 'מטווח' : 'פרטי'}</td>
                                    <td>{entry.imprintOnPistol || ''}</td>
                                    <td>{entry.ammunitionCaliber || ''}</td>
                                    <td style={{backgroundColor: entry.bulletCount === ' ' ? 'red' : 'none'}}>{entry.bulletCount}</td>
                                    <td>{entry.trainingEndTime?.split(',')[1] || entry.registrationDate?.split(',')[1]}</td>
                                    <td style={{background: entry.status === 'מבוטל' ? 'rgba(255, 0, 0, 0.377)' : entry.status === 'ממתין לאישור' ? 'rgba(255, 196, 0, 0.377)' :
                      entry.status === 'מאומת'? 'rgba(0, 255, 55, 0.377)' : 'inherit', cursor: entry.status === 'מבוטל' ? 'pointer' : 'default'}}
                      onClick={entry.status === 'מבוטל' ? () => handleDeleteCanceledUser(entry) : null}>
                      {entry.status}
                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="19">{isLoading ? 'טוען רשומות...' : 'אין רשומות זמינות'}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {selectedClient && (
                <ClientManagementPopup
                    client={selectedClient}
                    onClose={closePopup}
                    onUpdateClient={updateClient}
                />
            )}
        </div>
    );
};

export default ShootingLedger;