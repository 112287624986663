import React, { useState, useEffect } from 'react';
import '../styles/ShooterLogForm.css';

const ShooterLogForm = ({ isOpen, onClose, onSave, initialData = {} }) => {
  const [selectedDate, setSelectedDate] = useState('');

  const [formData, setFormData] = useState({
    rangeId: '',
    registrationDate: '',
    fullName: '',
    teudatZehut: '',
    phoneNumber: '',
    registrationType: '',
    healthDeclarationConfirm: false,
    licenseValidity: '',
    pistolManufacturer: '',
    imprintOnPistol: '',
    ammunitionCaliber: '',
    bulletCount: '',
    trainingEndTime: '',
    verifiedBy: '',
    managedBy: '',
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
      setSelectedDate(initialData.registrationDate?.split('-').slice(0, 3).join('-') || '');
    }
  }, [initialData]);

  useEffect(() => {
    if (selectedDate) {
      setFormData((prevData) => ({
        ...prevData,
        registrationDate: new Date(selectedDate).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })
      }));
    }
  }, [selectedDate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const generateDateKey = (date) => {
    const timeSuffix = new Date().getHours() < 12 ? '08' : '12';
    const formattedDate = new Date(date);
    return `${formattedDate.getFullYear()}-${String(formattedDate.getMonth() + 1).padStart(2, '0')}-${String(formattedDate.getDate()).padStart(2, '0')}-${timeSuffix}`;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const dateKey = generateDateKey(selectedDate); // Convert selectedDate to dateKey format
    console.log(formData, dateKey);
    onSave(formData,dateKey); // Use formatted dateKey
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="form-popup">
      <div className="form-container">
        <h2>{initialData ? 'ערוך יומן' : 'הוסף ליומן'}</h2>
        <form onSubmit={handleSubmit}>
         
          <label>
            תאריך כניסה לאימון:
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              required
            />
          </label>
          
          {/* Row 2 */}
          <label>
            שם ומשפחה:
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            תז:
            <input
              type="text"
              name="teudatZehut"
              value={formData.teudatZehut}
              onChange={handleChange}
              required
            />
          </label>
  
          {/* Row 3 */}
          <label>
            נייד:
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            סוג אימון:
            <select className='register-select' name="registrationType" value={formData.registrationType} onChange={handleChange} required>
                <option value="חידוש רישיון">חידוש רישיון</option>
                <option value="רענון תקופתי">רענון תקופתי</option>
                <option value="ירי חופשי">ירי השתפרות - "חופשי"</option>
                <option value="הכשרה ראשונית">הכשרה ראשונית</option>
                <option value="הכשרה ארגונית">הכשרה ארגונית</option>
                <option value="בדיקת תקינות נשק">בדיקת תקינות נשק</option>
              </select>
          </label>
  
          {/* Row 4 */}
          <label className="checkbox-group">
            הצהרת בריאות:
            <input
              type="checkbox"
              name="healthDeclarationConfirm"
              checked={formData.healthDeclarationConfirm}
              onChange={handleChange}
            />
          </label>
          <label>
            תוקף רישיון:
            <input
              type="date"
              name="licenseValidity"
              value={formData.licenseValidity}
              onChange={handleChange}
            />
          </label>
  
          {/* Row 5 */}
          <label>
            יצרן האקדח:
            <select className='register-select' name="pistolManufacturer" value={formData.pistolManufacturer} onChange={handleChange} required>
                <option value="none">בחר יצרן</option>
                <option value="Glock">Glock - גלוק</option>
                <option value="Sig Sauer">Sig Sauer - סיג סאוור</option>
                <option value="IWI">IWI</option>
                <option value="Smith & Wesson">Smith & Wesson - סמית' אנד ווסון</option>
                <option value="CZ">CZ - סי זד</option>
                <option value="Beretta">Beretta - ברטה</option>
                <option value="Karin">Karin - קארין</option>
                <option value="Walther">Walther - וולטר</option>
                <option value="BUL">BUL - בול</option>
                <option value="FN">FN - בראונינג</option>
                <option value="Ruger">Ruger - רוגר</option>
                <option value="Springfield">Springfield - ספרינגפילד</option>
                <option value="Shadow System">Shadow Systems - שאדו סיסטמס</option>
                <option value="Colt">Colt - קולט</option>
                <option value="Mossberg">Mossberg - מוסברג</option>
                <option value="HELLCAT">HELLCAT - הלקט</option>
                <option value="Taurus">Taurus - טאורוס</option>
                <option value="Emtan">אמתן</option>
                <option value="Other">אחר</option>
              </select>
            </label>
          {/* Conditionally render the input field if "Other" is selected */}
          {formData.pistolManufacturer === 'Other' && (
            <label>
              <input
                className='register-input'
                type="text"
                name="otherOption"
                value={formData.otherOption}
                onChange={handleChange}
                placeholder="הכנסה ידנית של יצרן"
                required
              />
            </label>
          )}

          <label>
            מספר כלי:
            <input
              type="text"
              name="imprintOnPistol"
              value={formData.imprintOnPistol}
              onChange={handleChange}
            />
          </label>
  
          {/* Row 6 */}
          <label>
            קליבר:
            <select className='register-select' name="ammunitionCaliber" value={formData.ammunitionCaliber} onChange={handleChange}>
              <option value="none"> בחר</option>
              <option value='9x19'>9x19</option>
              <option value='9x17'>9x17</option>
              <option value='7.65browning'>7.65 Browning</option>
              <option value='22LR'>22LR</option>
              <option value='22WM'>22WM</option>
              <option value='45AUTO'>45AUTO</option>
              <option value='44MAG'>44MAG</option>
              <option value='20S&W'>20S&W</option>
              <option value='22SHORT'>22SHORT</option>
              <option value='6.35'>6.35</option>
              <option value='38SPL'>38SPL</option>
              <option value='222REM'>222REM</option>
              <option value='22HORNET'>22HORNET</option>
              <option value='50AE'>50AE</option>
              <option value='32S&WLONG'>32S&WLONG</option>
              <option value='357MAG'>357MAG</option>
              <option value='38S&W'>38S&W</option>
              <option value='41AE'>41AE</option>
              <option value='7.63x25'>7.63x25</option>
              <option value='12Gauge'>12Gauge</option>
              <option value='5.56x45'>5.56x45</option>
            </select>
          </label>
          <label>
            כמות:
            <input
              type="text"
              name="bulletCount"
              value={formData.bulletCount}
              onChange={handleChange}
            />
          </label>
  
          {/* Row 8 */}
          <label className="full-width">
            סטטוס:
            <select value={formData.status} onChange={(e) => handleChange('status', e.target.value)}>
            <option value="ממתין לאישור">ממתין לאישור</option>
              <option value="מאומת">מאומת</option>
              <option value="שולם">שולם</option>
              <option value="מבוטל">מבוטל</option>
            </select>
          </label>
  
          {/* Buttons */}
          <div className="button-group">
            <button type="button" className="minimize-button" onClick={onClose}>סגור</button>
            <button type="submit" className="save-button">{initialData ? 'עדכון' : 'הוסף'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShooterLogForm;
