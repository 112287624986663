import React, { useState, useEffect } from 'react';
import { Box, Collapse, Button, Divider, LinearProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { SmartSelect } from './SmartInputSelect';
import '../styles/NewRegister.css';

const FileUpload = ({ formData, setFormData, handleNext, handlePrevious, text, language }) => {
    const [healthFile, setHealthFile] = useState(null);
    const [conditionalFile, setConditionalFile] = useState(null);
    const [fileError, setFileError] = useState({});
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [errors, setErrors] = useState({});
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const needsHealthFileUpload =
        formData.registrationType === 'חידוש רישיון' || formData.healthUpdateConfirm;

    const needsConditionalFileUpload = formData.registrationType === 'הכשרה ראשונית';

    const handleFileUpload = async (file, type) => {
        setIsUploading(true);
        const storage = getStorage();
        const storageRef = ref(storage, `${type}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress((prev) => ({ ...prev, [type]: progress }));
                if (progress === 100) {
                    setUploadedFiles((prev) => ({ ...prev, [type]: true }));
                }
            },
            (error) => {
                console.error('Error uploading file: ', error);
                setIsUploading(false);
            },
            async () => {
                const fileUrl = await getDownloadURL(uploadTask.snapshot.ref);
                setFormData((prevData) => ({
                    ...prevData,
                    [type === 'health' ? 'healthDeclarationFileUrl' : 'conditionalApprovalFileUrl']: fileUrl,
                }));
                setUploadProgress((prev) => ({ ...prev, [type]: 0 }));
                setIsUploading(false);
            }
        );
    };

    const onDrop = (acceptedFiles, type) => {
        const selectedFile = acceptedFiles[0];
        if (selectedFile) {
            if (type === 'health') {
                setHealthFile(selectedFile);
            } else {
                setConditionalFile(selectedFile);
            }
            setFileError((prev) => ({ ...prev, [type]: '' }));
            handleFileUpload(selectedFile, type);
        }
    };

    const validate = () => {
        let newErrors = {};

        if (needsHealthFileUpload && !healthFile) {
            newErrors.healthFile = 'יש להעלות הצהרת בריאות';
        }

        if (needsConditionalFileUpload && !conditionalFile) {
            newErrors.conditionalFile = 'יש להעלות אישור מותנה';
        }

        if (formData.registrationType === 'הכשרה ראשונית' && !formData.inWallSafeCommitment) {
            newErrors.inWallSafeCommitment = 'יש לאשר התחייבות התקנת כספת';
        }

        if (!formData.healthDeclarationConfirm && !formData.healthUpdateConfirm) {
            newErrors.healthDeclarationConfirm = 'יש לבחור הצהרת בריאות';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onNext = () => {
        if (validate()) {
            handleNext();
        }
    };

    const conditionalDropzone = useDropzone({
        onDrop: (files) => onDrop(files, 'conditional'),
        accept: {
            'application/pdf': ['.pdf'],
            'image/*': ['.jpg', '.jpeg', '.png']
        }
    });
    
    const healthDropzone = useDropzone({
        onDrop: (files) => onDrop(files, 'health'),
        accept: {
            'application/pdf': ['.pdf'],
            'image/*': ['.jpg', '.jpeg', '.png']
        }
    });

    const handleCheckboxChange = (e) => {
        const { name } = e.target;
        setFormData({
            ...formData,
            healthDeclarationConfirm: name === 'healthDeclarationConfirm',
            healthUpdateConfirm: name === 'healthUpdateConfirm',
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }} dir="rtl">
            <div className="chrome-tab">
                <p>
                {formData.registrationType} - {formData.fullName ? formData.fullName + " - " : " "} {formData.teudatZehut}
                </p>
            </div>
                {/* <SmartSelect
                    label="סוג אימון"
                    value={formData.registrationType}
                    onChange={(e) => {
                        setFormData({ ...formData, registrationType: e.target.value });
                    }}
                    options={[
                        { value: '', label: 'בחר אימון' },
                        { value: 'הכשרה ראשונית', label: 'הכשרה ראשונית' },
                        { value: 'הכשרה מקוצרת', label: 'הכשרה מקוצרת' },
                        { value: 'רענון תקופתי', label: 'רענון תקופתי אזרחי' },
                        { value: 'חידוש רישיון', label: 'חידוש רישיון אזרחי' },
                        { value: 'ירי חופשי', label: 'ירי השתפרות - "חופשי"' },
                        { value: 'הכשרה ארגונית', label: 'הכשרה ארגונית - מאבטח 9 שעות' },
                        { value: 'רענון ארגוני', label: 'רענון ארגוני - מאבטח 4.5 שעות' },
                        { value: 'מפעל ראוי', label: 'מפעל ראוי' },
                        { value: 'אימון החלפה', label: 'אימון החלפה' },
                        { value: 'אימון שיוך', label: 'אימון שיוך' },
                        { value: 'השלמת מכסת תחמושת', label: 'השלמת מכסת תחמושת' },
                        { value: 'בדיקת תקינות נשק', label: 'בדיקת תקינות נשק' }
                    ]}
                    required
                    error={!!errors.registrationType}
                    helperText={errors.registrationType}
                /> */}
            {/* אישור מותנה Section */}
            {needsConditionalFileUpload && (
                <Box sx={{ width: '100%', mb: 3, p: 2, border: "1px solid #ccc", borderRadius: "5px" }}>
                    <p className="register-subheading">העלה אישור מותנה</p>
                    <p> העלאת אישור מותנה - יש להעלות את הקובץ המקורי שנשלח אליכם במייל , במידה ואין לכם את הקובץ יש להעלות תמונה  תוך כדי הקפד על איכות צילום מיטבית</p>
                    <div {...conditionalDropzone.getRootProps()} className="dropzone">
                        <input {...conditionalDropzone.getInputProps()} />
                        <p>גרור ושחרר קובץ כאן, או לחץ לבחירת קובץ</p>
                    </div>
                    {conditionalFile && <p>{conditionalFile.name}</p>}
                    {isUploading && uploadProgress.conditional && (
                        <Box sx={{ width: '100%', mt: 2 }}>
                            <LinearProgress variant="determinate" value={uploadProgress.conditional} />
                        </Box>
                    )}
                    {errors.conditionalFile && <p className="helper-text">{errors.conditionalFile}</p>}
                </Box>
            )}

            <Divider sx={{ width: '100%', my: 2 }} />

            {/* הצהרת בריאות Section */}
            <Box sx={{ width: '100%', mb: 3, p: 2, border: "1px solid #ccc", borderRadius: "5px" }}>
                <p className="register-subheading">הצהרת בריאות</p>
                <p>אני, {formData.fullName}, ת.ז {formData.teudatZehut}, מצהיר/ה בזה בכתב כי לצורך ביצוע הכשרת רענון בהתאם לתקנות כלי הירייה התשע"ח-2018:</p>

                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                    {formData.registrationType !== 'חידוש רישיון' && (
                        <label className={`tag-checkbox ${formData.healthDeclarationConfirm ? 'selected' : ''}`}>
                            <input
                                type="checkbox"
                                name="healthDeclarationConfirm"
                                checked={formData.healthDeclarationConfirm || false}
                                onChange={handleCheckboxChange}
                                disabled={formData.registrationType === 'חידוש רישיון'}
                            />
                            לא חל כל שינוי בפרטי הצהרת הבריאות שלי
                        </label>)}
                    <label className={`tag-checkbox ${formData.healthUpdateConfirm ? 'selected' : ''}`}>
                        <input
                            type="checkbox"
                            name="healthUpdateConfirm"
                            checked={formData.healthUpdateConfirm || false}
                            onChange={handleCheckboxChange}
                        />
                        {formData.registrationType === 'חידוש רישיון' ? 'בעת חידוש רישיון חובה להעלות הצהרת בריאות חדשה' : ' חל שינוי בפרטים, מצ"ב הצהרת בריאות עדכנית'}
                    </label>
                    {needsHealthFileUpload && !isUploading && (
                        <div {...healthDropzone.getRootProps()} className="dropzone">
                            <input {...healthDropzone.getInputProps()} />
                            <p>גרור ושחרר קובץ כאן, או לחץ לבחירת קובץ</p>
                        </div>
                    )}
                    {healthFile && !isUploading && <p>{healthFile.name}</p>}
                    {isUploading && uploadProgress.health && (
                        <Box sx={{ width: '100%', mt: 2 }}>
                            <LinearProgress variant="determinate" value={uploadProgress.health} />
                        </Box>
                    )}
                    {errors.healthFile && <p className="helper-text">{errors.healthFile}</p>}
                </Box>
            </Box>

            <Divider sx={{ width: '100%', my: 2 }} />

            {/* התחייבות כספת Section */}
            {needsConditionalFileUpload && (
                <Box sx={{ width: '100%', mb: 3, p: 2, border: "1px solid #ccc", borderRadius: "5px" }}>
                    <p className="register-subheading">התחייבות התקנת כספת</p>
                    <h3 style={{ borderBottom: '2px solid #ccc ', width: '90%' }}>התחייבות מקבל/ת רישיון להתקנת כספת</h3>
                    <div>
                        <p>אני, {formData.fullName}, ת.ז {formData.teudatZehut}, מתחייב כי:</p>
                        <p>1. ידוע לי כי בהתאם להנחיות אגף לרישוי ופיקוח כלי ירייה במשרד לביטחון לאומי, המפורסמות באתר המשרד, עליי לאחסן את כלי הירייה הרשום ברישיון שאקבל בכספת שתותקן בביתי.</p>
                        <p>2. הכספת תותקן במקום מוסתר המצוי בשליטתי ואחריותי.</p>
                        <p>3. אני מתחייב/ת כי הכספת תשמש לאחסון כלי ירייה הנמצא בחזקתי בלבד, באופן שלא יתאפשר לאדם אחר, לרבות ליתר דיירי הבית, גישה אל כלי הירייה.</p>
                        <p>4. ידוע לי כי הפרת התחייבות זו כמוה כהפרת תנאי רישיון להחזקת כלי ירייה, על כל המשתמע.</p>
                    </div>
                    <label className={`tag-checkbox ${formData.inWallSafeCommitment ? 'selected' : ''}`}>
                        <input
                            type="checkbox"
                            name="inWallSafeCommitment"
                            checked={formData.inWallSafeCommitment || false}
                            onChange={(e) => setFormData({ ...formData, inWallSafeCommitment: e.target.checked })}
                        />
                        <span className="checkbox-label">
                            {formData.inWallSafeCommitment ? '✔' : text[language].confirmCommitment}
                        </span>
                    </label>
                    {errors.inWallSafeCommitment && <p className="helper-text">{errors.inWallSafeCommitment}</p>}
                </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }} className="nav-buttons">
                <Button variant="outlined" onClick={handlePrevious} >חזור</Button>
                <Button variant="contained" onClick={onNext} disabled={needsHealthFileUpload && !uploadedFiles.health || needsConditionalFileUpload && !uploadedFiles.conditional}>הבא</Button>
            </Box>
        </Box>
    );
};

export default FileUpload;
