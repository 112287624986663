import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import text from '../../text';

const Address = ({ formData, handleChange }) => {
  const { language } = useLanguage();

  return (
    <div className="address">
      <h3 style={{borderBottom:'2px solid #ccc ', width:'100%'}}>יש לצהיר על כתובת מגורים הנוכחית</h3>
      <label>{text[language].street}
        <input className='register-input' type="text" name="street" value={formData.street} onChange={handleChange} required />
      </label>
      <label>{text[language].homeNumber}
        <input className='register-input' type="text" name="homeNumber" value={formData.homeNumber} onChange={handleChange} required />
      </label>
      <label>{text[language].city}
        <input className='register-input' type="text" name="city" value={formData.city} onChange={handleChange} required />
      </label>
      <label>{text[language].zip}  (אופציונלי)
        <input className='register-input' type="text" name="zip" value={formData.zip} onChange={handleChange} />
      </label>
      {/* <label>{text[language].organizationNumber}
        <input className='register-input' type="text" name="organizationNumber" value={formData.organizationNumber} onChange={handleChange} required />
      </label> */}
    </div>
  );
};

export default Address;
