import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import text from '../../text';

function formatHebrewText(text) {
  let parts = text.split(/(\d\.\s)/).filter(part => part.trim() !== '');

  let header = parts.shift();

  let formattedText = parts.reduce((acc, part, index) => {
    if (index % 2 === 0) {
      return acc + `<p>${part.trim()}`;
    } else {
      return acc + part.trim() + `</p>`;
    }
  }, `<p>${header.trim()}</p>`);

  return formattedText;
}

const HealthDeclaration = ({ formData, setFormData }) => {
  const { language } = useLanguage();
  const [uploadProgress, setUploadProgress] = useState(0); // State for upload progress
  const [uploaded, setUploaded] = useState(false);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
      ...(name === 'healthDeclarationConfirm' && checked ? { healthUpdateConfirm: false } : {}),
      ...(name === 'healthUpdateConfirm' && checked ? { healthDeclarationConfirm: false } : {}),
    }));
  };

  const handleFileUpload = async (e) => {
    const storage = getStorage();
    const file = e.target.files[0];

    if (!file) return;

    const storageRef = ref(storage, `health_declarations/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
        if (progress === 100) {
          setUploaded(true);
        }
      },
      (error) => {
        console.error('Error uploading file: ', error);
      },
      async () => {
        const fileUrl = await getDownloadURL(uploadTask.snapshot.ref);
        setFormData((prevData) => ({
          ...prevData,
          healthDeclarationFileUrl: fileUrl,
        }));
        setUploadProgress(0);
      }
    );
  };

  return (
    <div className="health-declaration">
      <h3 style={{borderBottom:'2px solid #ccc ', width:'100%'}}>הצהרת בריאות</h3>
      <div>
        <p>אני, {formData.fullName}, ת.ז {formData.teudatZehut}, מצהיר/ה בזה בכתב כי לצורך ביצוע הכשרת רענון בהתאם לתקנות כלי הירייה התשע"ח-2018:</p>
      </div>
      
      <div className="checkboxes">
        <label className="checkbox-row">
          <p style={{width:'80%'}}>{text[language].healthDeclarationConfirm}</p>
          <input
            type="checkbox"
            name="healthDeclarationConfirm"
            checked={formData.healthDeclarationConfirm}
            onChange={handleCheckboxChange}
            style={{width:'20%'}}
          />
        </label>
        <label className="checkbox-row">
          <p style={{width:'80%'}}>{text[language].healthUpdateConfirm}</p>
          <input
            type="checkbox"
            name="healthUpdateConfirm"
            checked={formData.healthUpdateConfirm}
            onChange={handleCheckboxChange}
            style={{width:'20%'}}
          />
        </label>
      </div>

      <p className='upload-file'>במידה ויש ברשותכם הצהרת בריאות חתומה יש להעלות אותה כאן - יש להעלות קובץ PDF או להקפיד על איכות צילום מיטבית

      {/* File Upload Button */}
      <input
        type="file"
        accept="application/pdf,image/*"
        onChange={handleFileUpload}
        style={{ display: 'block', marginBottom: '10px', marginTop: '10px' }}
      />

      {/* Show Progress Bar */}
      {uploadProgress > 0 && (
        <div className="progress-bar">
          <div className="progress" style={{ width: `${uploadProgress}%` }}>
            {uploadProgress.toFixed(2)}%
          </div>
        </div>
      )}

      {formData.healthDeclarationFileUrl && uploadProgress === 100 && (
        <div className="uploaded-file">
          <p>הקובץ הועלה בהצלחה: <a href={formData.healthDeclarationFileUrl} target="_blank" rel="noopener noreferrer">צפה בקובץ</a></p>
        </div>
      )}
      </p>

      {/* Show Required Message for Specific Types */}
      {(formData.registrationType === 'חידוש רישיון' || formData.registrationType === 'הכשרה ראשונית')  && !uploaded &&(
        <p className="required-message" style={{ color: 'red' }}>
          העלאת קובץ נדרשת עבור סוג אימון זה.
        </p>
      )}
    </div>
  );
};

export default HealthDeclaration;
