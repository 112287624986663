import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import text from '../text';
import '../styles/NewLicense.css';
import { logEvent, analytics, db } from './firebase';
import { collection, doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import youtubeIcon from '../icons/icons8-youtube-100.png';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 110px;
`;

const Title = styled.h2`
  font-size: 2em;
  color: #0044cc;
  margin-bottom: 0.5em;
`;

const Paragraph = styled.p`
  font-size: 1.1em;
  margin-bottom: 1em;
`;

const List = styled.ol`
  margin-left: 1.5em;
`;

const ListItem = styled.li`
  margin-bottom: 0.5em;
`;

const VideoSection = styled.div`
  margin-top: 2em;
  text-align: center;
`;

const YoutubeBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const YoutubeIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-left: 10px;
`;

const NewLicenseContent = () => {
  const { language } = useLanguage();
  const CHANNEL_ID = 'UC-sW4_BaoZG8XHPpUwAMyPg';

  const updateCounter = async (event) => {
    const eventRef = doc(collection(db, 'analytics'), event);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      await updateDoc(eventRef, { counter: increment(1) });
    } else {
      await setDoc(eventRef, { event, counter: 1, timestamp: new Date() });
    }
  };

  const handleYoutubeClick = async () => {
    logEvent(analytics, 'youtube_channel_visited');
    await updateCounter('youtube_channel_visited');
  };

  return (
    <Container>
      <Helmet>
        <title>מטווח שי לוי - מדריך להוצאת רישיון חדש</title>
        <meta name="description" content="מטווח שי לוי - קרני שומרון, ביקורות אקדחים, אימונים, חידוש רישיון ועוד" />
        <meta name="keywords" content=", נרתיק לאקדח, זיג, Sig Sauer, הלקט , אקדח חדש, אקדח משומש דיקת תיקנות נשק, רענון תקופתי, מטווח , מטווח שי לוי , הכשרה ראשונית , רישיון לאקדח, גלוק, Glock, מאבטחים, חידוש רישיון, קרני שומרון" />
      </Helmet>
      <Title>{text[language].newLicenseTitle2}</Title>
      <Paragraph>{text[language].licenseExplanation}</Paragraph>
      
      <List>
        <ListItem>
          {text[language].healthDeclarationInfo}
          <a href="https://www.gov.il/BlobFolder/service/issue_firearms_license_to_a_private_individual/en/fa_health_dec.pdf" target="_blank" rel="noopener noreferrer"> {text[language].downloadForm}</a>
        </ListItem>
        <ListItem>{text[language].idWithAppendix}</ListItem>
        <ListItem>{text[language].militaryApproval}</ListItem>
        <ListItem>
          {text[language].eligibilityCriteria}
          <br />
          {text[language].eligibilityExamples}
          <ul>
            <li>{text[language].eligibleSettlement}</li>
            <li>{text[language].combatCertificate}</li>
            <li>{text[language].officerCertificate}</li>
            <li>
              <a href="https://ishurim.prat.idf.il/Account/Login?ReturnUrl=%2f%3fAspxAutoDetectCookieSupport%3d1&AspxAutoDetectCookieSupport=1" target="_blank" rel="noopener noreferrer">{text[language].idfApprovalLink}</a>
            </li>
            <li>
              <a href="https://www.gov.il/apps/mops/firearm_license_calculator/" target="_blank" rel="noopener noreferrer">{text[language].eligibilityCalculator}</a>
            </li>
          </ul>
        </ListItem>
      </List>
      <Paragraph>
        {text[language].scanAndSubmit}
        <br />
        <a href="https://www.gov.il/he/service/issue_firearms_license_to_a_private_individual" target="_blank" rel="noopener noreferrer">{text[language].ministryLink}</a>
      </Paragraph>
      <h3>{text[language].afterInterview}</h3>
      <List>
        <ListItem>{text[language].conditionalApprovalReceived}</ListItem>
        <ListItem>{text[language].visitShootingRange}</ListItem>
        <ListItem>{text[language].chooseGun}</ListItem>
        <ListItem>{text[language].trainingSession}</ListItem>
        <ListItem>{text[language].documentsSubmission}</ListItem>
        <ListItem>{text[language].getTemporaryLicense}</ListItem>
        <ListItem>{text[language].collectGun}</ListItem>
      </List>
      <VideoSection>
        {text[language].youtubeVideo}
        <br />
        <iframe width="560" height="315" src="https://www.youtube.com/embed/d9ouDZ1hX0Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </VideoSection>
      <YoutubeBanner>
        <a href={`https://www.youtube.com/channel/${CHANNEL_ID}?sub_confirmation=1`}
          target="_blank" 
          rel="noopener noreferrer"
          onClick={handleYoutubeClick}>
          <p>{text[language].visitYoutubeChannel}</p>
          <YoutubeIcon src={youtubeIcon} alt="YouTube" />
        </a>
      </YoutubeBanner>
    </Container>
  );
};

export default NewLicenseContent;
