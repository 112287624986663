import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
// Import the new CSS file
import '../styles/NewRegister.css';
// Import SmartInput and SmartSelect
import { SmartInput } from './SmartInputSelect';

const PersonalInformation = ({ formData, setFormData, handleNext, handlePrevious }) => {
  const [errors, setErrors] = useState({});

  // Validate the fields according to the specified rules.
  const validate = () => {
    let newErrors = {};

    // Validate full name: Must be filled and contain only Hebrew characters and spaces.
    if (!formData.fullName.trim()) {
      newErrors.fullName = 'יש למלא שם מלא בעברית';
    } else if (!/^[\u0590-\u05FF\s]+$/.test(formData.fullName.trim())) {
      newErrors.fullName = 'שם מלא יכול להכיל רק תווים בעברית ורווחים';
    }

    // Validate phone number: Must be filled, start with 05 and be exactly 10 digits.
    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = 'יש למלא את מספר הטלפון';
    } else if (!/^05\d{8}$/.test(formData.phoneNumber.trim())) {
      newErrors.phoneNumber = 'מספר טלפון לא חוקי. יש להתחיל עם 05 ולהיות 10 ספרות';
    }

    // Validate email only if it is filled.
    if (formData.email.trim()) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.trim())) {
        newErrors.email = 'כתובת דוא"ל לא חוקית';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onNext = () => {
    if (validate()) {
      handleNext();
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }} dir="rtl">
      <div className="chrome-tab">
        <p>
          {formData.registrationType} - {formData.teudatZehut}
        </p>
      </div>
      <SmartInput
        label="שם מלא"
        value={formData.fullName}
        onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
        required
        error={!!errors.fullName}
        helperText={errors.fullName}
      />
      <SmartInput
        label="מספר טלפון"
        type="tel"
        value={formData.phoneNumber}
        onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
        required
        error={!!errors.phoneNumber}
        helperText={errors.phoneNumber}
      />
      <SmartInput
        label="דואל (אופציונלי)"
        type="email"
        value={formData.email}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        error={!!errors.email}
        helperText={errors.email}
      />
      <p className="email-request">
        נשמח לשלוח לך את מסמכי האימון, עדכונים, ותזכורות לחידוש ישירות למייל שלך. הזנת המייל, מהווה את הסכמתך לקבלת הודעות לתיבת הדואר שלך. ניתן לבטל את ההרשמה בכל עת
      </p>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }} className="nav-buttons">
        <Button variant="outlined" onClick={handlePrevious} >
          קודם
        </Button>
        <Button variant="contained" onClick={onNext}>
          הבא
        </Button>
      </Box>
    </Box>
  );
};

export default PersonalInformation;
